// Make sure the charset is set appropriately
@charset "UTF-8";

// Core
// The following imports bootstrap the theme.
// You may comment out bourbon if you don't want to use its library of mixins.
// The theme will have compilation errors if Foundation is not imported.
// Global Zurb Foundation variables.
@import "vendor/foundation/variables";
// Comment out this import if you don't want to use normalize.
@import "vendor/foundation/normalize";
// Comment out this import below if you are customizing you imports below.
@import "vendor/foundation/foundation";
// Comment out this import if you don't want to use Bourbon, a replacement for Compass mixins.

@keyframes scaleGal {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.13);
  }
}

.clear {
  clear: both;
}

body {
  background-color: $light-primary-color;
  height: auto;
  &.show-mobile {
    overflow: hidden;
    header.l-header {
      .mobile-fix-menu {
        left: 0;
      }
    }
  }
}

html body.admin-menu {
  margin-top: 40px !important;
  &.node-type-beet-pflanzen {
    margin-top: 0 !important;
  }
}

#admin-menu .dropdown li {
  font-size: 0.7rem;
}

h1, h2, h3, h4, h5, h6 {
  font-family: museo_slab500;
}

input[type=text], input[type=password], input[type=tel], input[type=email], textarea, select {
  font-size: 1.1rem;
  line-height: 1.4;
  min-height: 45px;
  max-width: 100%;
  color: $grey;
  box-shadow: none;
  border: 1px solid #ccc;
  border-radius: 0;
}

input[type="file"], input[type="checkbox"], input[type="radio"] {
  margin: 0;
}

textarea {
  min-height: 150px;
}

.webform-component-textarea .grippie, .form-item-commerce-payment-payment-details-installment {
  display: none;
}

header.l-header {
  border-top: 4px solid $logo-color;
  &.fixed-header {
    .meta {
      margin: 0 0 86px;
    }
    .main-header {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
    }
  }
  .meta {
    background-color: darken($light-primary-color, 5);
    color: $grey;
    padding: 7px 0 4px;
    a {
      color: $grey;
      line-height: 25px;
      vertical-align: middle;
      margin: 0 12px 0 0;
      font-family: museo_slab500;
      > * {
        vertical-align: middle;
        margin: 0 0 3px;
        display: inline-block;
      }
      &:hover {
        color: $primary-color;
      }
    }
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      li {
        float: right;
        margin: 0 0 0 20px;
        a {
          text-transform: uppercase;
          &.active {
            color: $primary-color;
          }
        }
      }
    }
  }
  .main-header {
    background: white;
    z-index: 200;
    position: relative;
    height: 85px;
    padding: 5px 0;
    z-index: 30;
    border-bottom: 1px solid rgba(130, 130, 130, 0.4);
    #logo {
      display: inline-block;
      margin: 3px 0;
      img {
        height: 66px;
        width: auto;
      }
    }
    .mobile-menu-link {
      display: inline-block;
      float: right;
      font-size: 1.4rem;
      margin: 25px 0;
      cursor: pointer;
      > * {
        vertical-align: middle;
      }
      .erntemich-icon-menu {
        margin: 0 0 2px;
        display: inline-block;
      }
    }
    a {
      color: #443d38;
      text-transform: uppercase;
      padding: 0 8px;
      font-size: rem-calc(18);
      &:hover {
        color: $primary-color;
      }
      &.active {
        color: $primary-color;
        border-bottom: 5px solid $secondary-color;
        padding: 0 8px 10px;
      }
    }
    nav#main-menu {
      float: right;
      height: 100%;
      .block-menu {
        display: inline-block;
        margin: 8px 0 0;
        vertical-align: middle;
        ul {
          list-style: none;
          margin: 0;
          padding: 0;
          li {
            display: inline-block;
            margin: 0 0 0 20px;
            line-height: 56px;
            vertical-align: top;
            &.expanded {
              ul.menu {
                display: none;
                position: absolute;
                padding: 16px 0 0;
                li {
                  display: block;
                  background: white;
                  border: 1px solid rgba(130, 130, 130, 0.4);
                  border-top: none;
                  line-height: 50px;
                  margin: 0;
                  height: 50px;
                  padding: 0 15px;
                  a {
                    font-size: 1rem;
                    padding: 0;
                    &.active {
                      border-bottom: none;
                      padding: 0;
                    }
                  }
                }
              }
              &:hover {
                ul.menu {
                  display: block;
                }
              }
            }
            a {
              font-weight: bold;
              display: block;
            }
          }
        }
      }
      .deviderPipe {
        margin: 0 10px;
      }
      a.card {
        display: inline-block;
        .erntemich-icon-basket {
          font-size: 1.2rem;
        }

      }
    }
  }
  .mobile-fix-menu {
    position: fixed;
    top: 0;
    left: 100%;
    width: 100%;
    height: 100%;
    background-color: $primary-color;
    color: white;
    text-align: center;
    padding: 100px 10px 30px;
    z-index: 255;
    transition: all 0.5s;
    overflow: auto;
    a.logo {
      position: absolute;
      top: 25px;
      left: 25px;
      width: 75px;
      height: 75px;
      vertical-align: middle;
      text-align: center;
      display: inline-block;
      background-color: white;
      border-radius: 50%;
      padding: 16px 0;
      img {
        width: 60px;
        height: auto;
      }
    }
    .erntemich-icon-arrow-right2 {
      color: white;
      cursor: pointer;
      position: absolute;
      top: 40px;
      right: 25px;
      font-size: 2.4rem;
    }
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      li.expanded {
        margin: 0 0 15px;
        ul.menu {
          li {
            a {
              font-size: 1rem;
              line-height: 1.2;
            }
          }
        }
      }
    }
    a {
      color: white;
      font-size: 1.3rem;
      font-family: museo_slab500;
      line-height: 38px;
    }
    .item {
      font-size: 1rem;
      line-height: 24px;
      color: $light-primary-color;
    }
    .menu-name-menu-subnav {
      margin: 30px 0 0;
      ul {
        li {
          a {
            font-size: 1.1rem;
            line-height: 20px;
            font-family: 'ubuntu';
          }
        }
      }
    }
  }
}

.l-messages {
  a {
    color: #ffef00;
    &:hover {
      color: darken(#ffef00, 10);
    }
  }
}

.commerce-directdebit-logo {
  display: none;
}

fieldset {
  .info-box {
    padding: 10px;
    background: lighten(yellow, 40);
    border: 1px solid yellow;
    font-size: 12px;
  }
}

.checkout-help {
  margin: 0 0 35px;
}

.node-produkt {
  .commerce-product-field-commerce-price {
    font-size: 1.5rem;
    margin: 0 0 6px;
  }
  .commerce-product-field-price {
    margin: 0 0 20px;
  }
  .field-name-field-beschreibung {
    margin: 35px 0 0;
  }
  .form-item {
    margin: 0;
  }
  .attribute-widgets {
    min-width: 150px;
  }
  .form-submit {
    display: block;
    width: 100%;
  }
  .field-name-field-produkt-bilder {
    position: relative;
    .owl-nav {
      position: absolute;
      top: 45%;
      width: 100%;
      .owl-next {
        position: absolute;
        color: white;
        font-size: 2rem;
        right: 10px;
      }
      .owl-prev {
        position: absolute;
        color: white;
        font-size: 2rem;
        left: 10px;
      }
    }
    .owl-dots {
      text-align: center;
      .owl-dot {
        display: inline-block;
        margin: 5px 3px;
        width: 10px;
        height: 10px;
        border: 2px solid #ccc;
        border-radius: 50%;
        &.active {
          background: $primary-color;
        }
      }
    }
  }
}

.view-fertigbeete {
  > .view-content {
    .views-row {
      text-align: center;
      padding: 12px;
      border-radius: 4px;
      border: 1px solid #e3e3e3;
      font-size: 1.05rem;
      cursor: pointer;
      display: inline-block;
      background: $primary-color;
      &:hover {
        background-color: lighten($primary-color, 45);
      }
    }
  }
  .nameFertigbeet {
    border: 1px solid #e3e3e3;
    border-radius: 3px;
    margin: 5px 0;
    padding: 6px 8px;
    font-size: 0.8rem;
    display: block;
    width: 100%;
    box-sizing: border-box;
    height: 34px;
    line-height: 34px;
  }
  .saveFertigbeet {
    background-color: $primary-color;
    color: white;
    padding: 8px;
    border-radius: 0;
    border: none;
    margin: 5px 0;
    display: block;
    font-size: 0.9rem;
    width: 100%;
    cursor: pointer;
    box-sizing: border-box;
    &:hover {
      background-color: darken($primary-color, 10);
    }
  }
}

.vorg_beet {
  margin: 0 0 40px;
  .lbe-switch {
    vertical-align: middle;
  }

  /* The switch - the box around the slider */
  .switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
    vertical-align: middle;
    input {
      display: none;
    }
    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #ccc;
      -webkit-transition: .4s;
      transition: .4s;
      &:after {
        content: "nein";
        position: relative;
        display: block;
        top: 36px;
        font-size: 0.8rem;
        text-align: center;
        left: 0;
      }
    }
    .slider:before {
      position: absolute;
      content: "";
      height: 26px;
      width: 26px;
      left: 4px;
      bottom: 4px;
      background-color: white;
      -webkit-transition: .4s;
      transition: .4s;
    }

    input:checked + .slider {
      background-color: $primary-color;
      &:after {
        content: "ja";
      }
    }

    input:focus + .slider {
      box-shadow: 0 0 1px $primary-color;
    }

    input:checked + .slider:before {
      -webkit-transform: translateX(26px);
      -ms-transform: translateX(26px);
      transform: translateX(26px);
    }

    .slider.round {
      border-radius: 34px;
    }
    .slider.round:before {
      border-radius: 50%;
    }
  }
}

.view-id-pflanzeninfo {
  > .attachment {
    display: inline-block;
    width: 250px;
    margin: 0 15px 0 0;
    vertical-align: top;
    height: 600px;
    overflow: auto;
    border: 1px solid $primary-color;
    white-space: nowrap;
    background-color: rgba(240, 240, 240, 0.95);
    .views-summary {
      list-style: none;
      li {
        margin: 0;
      }
    }
    a {
      color: darken($primary-color, 5);
      font-size: 1.2rem;
      font-weight: 300;
      display: block;
      padding: 8px 10px;
      &.active {
        font-weight: 400;
        background: rgba(0, 0, 0, 0.2);
      }
      &:hover {
        text-decoration: none;
        background: rgba(0, 0, 0, 0.2);
      }
    }
  }
  &.view-display-id-block_1, &.view-display-id-page_1 {
    > .view-content {
      display: inline-block;
      width: calc(100% - 280px);
      vertical-align: top;
      .entity-commerce-product {
        > .content {
          > .field-wrapper {
            margin: 10px 0;
          }
        }
      }
      .field-name-field-pflanzenfamilie {
        margin: 10px 0;
      }
      .field-name-field-beschreibung {
        .field-label {
          float: none;
        }
      }
      .field-name-field-versandpreis {
        .field-label {
          display: inline-block;
        }
      }
    }
  }
}

main.l-main {
  margin: 0 auto 35px;
  padding-top: 30px;
  &.row {
    .main {
      background-color: white;
      padding-top: 25px;
      padding-bottom: 35px;
    }
  }
  .main {
    h1#page-title {
      font-size: rem-calc(38);
      font-family: museo_slab500;
    }
  }
}

.node-type-startseite, .node-type--ber-uns {
  main.l-main {
    margin: 0 auto;
    padding-top: 0;
  }
}

.views-exposed-widgets {
  .views-submit-button {
    display: none;
  }
  .form-type-select {
    position: relative;
    select {
      border: 3px solid $secondary-color;
      background: transparent !important;
      z-index: 10;
      position: relative;
      color: $black;
      min-width: 220px;
      height: 60px;
      line-height: 60px;
      padding: 0 70px 0 20px;
      text-transform: uppercase;
      font-size: 1.125rem;
      transition: all 300ms;
      margin: 0 0 20px;
      cursor: pointer;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
    }
    &:after {
      content: "\e907";
      display: block;
      font-family: 'erntemich';
      position: absolute;
      top: 3px;
      right: 3px;
      height: 54px;
      width: 54px;
      background-color: transparent;
      color: white;
      text-align: center;
      line-height: 54px;
      color: $black;
      background-color: white;
      z-index: 5;
      font-size: 1.6rem;
      border-left: 3px solid $secondary-color;
    }
  }
}

.views-exposed-widget {
  .description {
    display: none;
  }
}

$s_green: #008744;
$s_blue: #0057e7;
$s_red: #d62d20;
$s_yellow: #ffa700;
$s_white: #eee;

// scaling... any units
$s_width: 100px;

// demo-specific

.showbox {
  position: fixed;
  display: none;
  z-index: 300;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 15% 5%;
  background: rgba(20, 20, 20, 0.8);
  color: white;
  text-align: center;
}

// end demo-specific

.loader {
  position: relative;
  margin: 0px auto;
  width: $s_width;
  &:before {
    content: '';
    display: block;
    padding-top: 100%;
  }
}

.circular {
  animation: rotate 2s linear infinite;
  height: 100%;
  transform-origin: center center;
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.path {
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  animation: dash 1.5s ease-in-out infinite,
  color 6s ease-in-out infinite;
  stroke-linecap: round;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px;
  }
}

@keyframes color {
  100%, 0% {
    stroke: $s_red;
  }
  40% {
    stroke: $s_blue;
  }
  66% {
    stroke: $s_green;
  }
  80%, 90% {
    stroke: $s_yellow;
  }
}

#footer-map {
  height: 550px;
  position: relative;
}

.node-standort {
  .map {
    height: 450px;
    position: relative;
    > .headline {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      margin: 50px 0 0;
      z-index: 10;
      display: inline-block;
      h2 {
        color: $black;
        display: inline-block;
      }
    }
    .mapContainer {
      height: 100%;
    }
  }
  .opening {
    padding: 25px 0 35px;
    font-size: rem-calc(18);
    line-height: 1.4;
    .text {
      .field-name-field-off-tag {
        width: 130px;
        font-size: 1rem;
        display: inline-block;
        margin-right: 40px;
      }
      .field-name-field-off-time {
        width: calc(100% - 170px);
        display: inline-block;
        font-size: 1rem;
      }
    }
    .small {
      font-size: rem-calc(14);
      margin: 25px 0 30px;
      display: block;
    }
  }
}

.l-footer {
  background-color: #3f3f3f;
  color: white;
  .knowFrom {
    background-color: white;
    padding: 35px 0 50px;
    overflow: hidden;
    border-top: 5px solid $primary-color;
    h2 {
      font-family: museo_slab500;
    }
    .view-id-bekannt_aus {
      position: relative;
      .erntemich-icon-arrow-left {
        position: absolute;
        top: 35%;
        left: 25px;
        color: $black;
        font-size: 3rem;
        cursor: pointer;
      }
      .erntemich-icon-arrow-right {
        position: absolute;
        top: 35%;
        right: 25px;
        color: $black;
        font-size: 3rem;
        cursor: pointer;
      }
    }
    .node-bekannt-aus {
      margin: 0 80px;
      position: relative;
      .gradient {
        position: absolute;
        height: 100%;
        width: 80px;
        top: 0;
        z-index: 10;
        &.left {
          background-image: linear-gradient(to right, transparent, white);
          right: 0;
        }
        &.right {
          background-image: linear-gradient(to left, transparent, white);
          left: 0;
        }
      }
      .field-name-field-bekanntaus-logos {
        white-space: nowrap;
        overflow: hidden;
        padding: 0 35px;
        text-align: center;
        img {
          margin: 0 30px;
          opacity: 0.65;
        }
      }
    }
  }
  .downFooter {
    background-color: #212121;
    padding: 25px 0 35px;
    font-size: rem-calc(18);
    line-height: 1.4;
    color: white;
    h3, h5 {
      color: white;
    }
    ul {
      margin: 0;
      padding: 0;
      list-style: none;
      li {
        font-style: normal;
      }
    }
    label {
      color: white;
    }
    .button {
      background-color: $primary-color;
      display: block;
      width: 100%;
      font-size: 1.2rem;
      &:hover {
        background-color: darken($primary-color, 10);
      }
    }
    .footer-adresse {
      margin: 0 0 30px;
    }
  }
  .downSide {
    background-color: #030503;
    color: white;
    text-transform: uppercase;
    line-height: 50px;
    .column > span {
      display: block;
    }
    .right {
      color: #686868;
      a {
        color: #7f7f0d;
      }
    }
  }
}

.view-id-standorte {
  position: relative;
  .owl-prev {
    color: black;
    font-size: 3.2rem;
    position: absolute;
    left: 25px;
    top: 250px;
  }
  .owl-next {
    color: black;
    font-size: 3.3rem;
    position: absolute;
    right: 25px;
    top: 250px;
  }
  .owl-dots {
    position: absolute;
    text-align: center;
    z-index: 10;
    top: 16px;
    width: 100%;
    .owl-dot {
      display: inline-block;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      border: 2px solid black;
      margin: 5px;
      &.active {
        background: black;
      }
    }
  }
}

.node-startseite {
  position: relative;
  overflow: hidden;
  .galerieStart {
    position: relative;
    overflow: hidden;
  }
  .backgroundStart {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $black;
    .backImg {
      position: absolute;
      opacity: 0;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      transition: opacity 0.6s;
      //animation: scaleGal cubic-bezier(0.445, 0.05, 0.55, 0.95) 12s;
      //animation-direction: alternate;
      //animation-iteration-count: infinite;
      &.visible {
        opacity: 0.85;
      }
    }
    img {
      width: 120%;
      height: auto;
      min-height: 100%;
    }
  }
  .inner {
    position: relative;
    z-index: 5;
    max-width: 850px;
    padding: 80px 15px 60px;
    text-align: center;
    color: white;
    margin: 0 auto;
    h1 {
      font-family: museo_slab500;
      padding: 10px;
      background-color: $logo-color;
      color: white;
      font-size: rem-calc(42);
      margin: 0 0 40px;
      display: inline-block;
    }
    .field-name-field-start-teasertext {
      font-size: rem-calc(22);
      text-shadow: 3px 3px 6px rgba(0, 0, 0, 0.9);
      font-family: museo_slab500;
      p {
        font-size: rem-calc(24);
      }
    }
  }
}

.button.special {
  background-color: $secondary-color;
  font-family: museo_slab500;
  font-size: rem-calc(24);
  border-radius: 8px;
  display: inline-block;
  margin: 20px 0;
  &.small {
    margin: 0;
    font-size: rem-calc(18);
    padding: 10px;
  }
  &.rounded {
    border-radius: 50%;
    height: 100px;
    width: 100px;
    font-size: 3rem;
    padding: 26px 0;
    margin: 0 0 -85px;
  }
}

.node--ber-uns {
  .about {
    padding: 30px 0 40px;
    .erntemich {
      margin: 0 0 -25px;
    }
  }
}

.para_idea {
  padding: 75px 0 35px;
  background: white;
  h2.titl {
    font-family: museo_slab500;
    text-align: center;
    font-size: 2.5rem;
    strong {
      font-weight: bold;
      text-transform: uppercase;
      color: $primary-color;
    }
  }
  .texter {
    line-height: 1.4;
    position: relative;
    padding: 20px 0 0 60px;
    min-height: 200px;
    &:before {
      content: "!";
      color: $primary-color;
      font-size: 16rem;
      line-height: 1;
      position: absolute;
      left: -50px;
      top: -40px;

    }
  }
}

.para_teaser {
  .whiter {
    border-bottom: 1px solid #e3e3e3;
    .small-4 {
      padding-right: 0;
    }
    .text-content {
      > a {
        background-color: $secondary-color;
        font-family: museo_slab500;
        font-size: rem-calc(18);
        border-radius: 8px;
        display: inline-block;
        margin: 10px 0;
        color: white;
        padding: 8px 12px;
      }
    }
  }
  &:last-of-type {
    .whiter {
      border: none;
    }
  }
}

@media (max-width: 480px) {
  .para_teaser {
    .whiter {
      .small-4 {
        width: 100%;
        margin: 0 0 15px;
      }
      .small-8 {
        width: 100%;
      }
    }
  }
}

.whiter {
  background-color: white;
  padding-top: 20px;
  padding-bottom: 20px;
}

.para_knowledge {
  margin: 0 0 40px;
  .row {
    background: #ececec;
    padding: 50px 0;
  }
  h2.titl {
    font-family: museo_slab500;
    text-align: center;
    font-size: 2.5rem;
    position: relative;
    z-index: 5;
  }
  .plant {
    position: absolute;
    text-align: center;
    width: 100%;
    height: 100%;
    z-index: 0;
    top: 30px;
    left: 0;
    .ast {
      display: inline-block;
      width: 3px;
      background-image: linear-gradient(transparent 0%, $primary-color 15%, $primary-color 100%);
      height: 100%;
    }
    .wurzel {
      margin-left: -52px;
    }
  }
  .field-name-field-about-wissenwertes {
    position: relative;
    z-index: 5;
    padding: 50px 0;
    min-height: 250px;
    ul {
      li {
        margin: 0 0 8px;
      }
    }
    .entity-field-collection-item {
      width: calc(50% - 42px);
      margin-bottom: -100px;
      &:nth-child(2n) {
        margin: 0 0 0 50%;
        width: 50%;
        .content {
          margin-left: 42px;
          &:before {
            content: "";
            position: absolute;
            top: 30px;
            left: -44px;
            background: url('/sites/all/themes/em2/images/right_plant.png') no-repeat 0 0;
            width: 60px;
            height: 190px;
          }
        }
      }
      .content {
        background: white;
        padding: 30px 25px 20px;
        position: relative;
        .field-name-field-knowledge-titel {
          font-size: 1.6rem;
          color: $primary-color;
          margin: 0 0 10px;
        }
        &:before {
          content: "";
          position: absolute;
          top: 30px;
          right: -40px;
          background: url('/sites/all/themes/em2/images/left_plant.png') no-repeat 0 0;
          width: 52px;
          height: 170px;
        }
      }
    }
  }
}

.para_benefit {
  padding: 60px 0;
  background: white;
  h2.titl {
    font-family: museo_slab500;
    text-align: center;
    font-size: 2.5rem;
    strong {
      font-weight: bold;
      text-transform: uppercase;
      color: $primary-color;
    }
  }
  .field-name-field-about-vorteile {
    padding: 20px 0 0;
    > .field-items {
      > .field-item {
        padding: 0 0 0 50px;
        position: relative;
        &:before {
          position: absolute;
          top: 0;
          content: "";
          background: url('/sites/all/themes/em2/images/dot.png') no-repeat 0 0;
          width: 50px;
          height: 50px;
          left: 0;
        }
      }
    }
  }
  .spruch {
    font-size: 1.5rem;
    color: $primary-color;
    font-family: museo_slab500;
    text-align: center;
    margin: 50px 0 0;
  }
}

.para_who {
  padding: 60px 0;
  background: #5d5d5d;
  h2.titl {
    font-family: museo_slab500;
    text-align: center;
    font-size: 2rem;
    color: white;
    margin: 0 0 30px;
    strong {
      font-weight: bold;
      text-transform: uppercase;
      color: $primary-color;
    }
  }
  .column {
    color: white;
    line-height: 1.5;
    font-size: 1.2rem;
    &.medium-4 {
      margin: 0 0 20px;
      text-align: center;
      padding-right: 0;
    }
    p {
      font-size: 1.1rem;
    }
  }
}

#back-top {
  position: fixed;
  background: white;
  width: 65px;
  height: 65px;
  bottom: 60px;
  right: 60px;
  border: 3px solid $primary-color;
  color: $primary-color;
  text-align: center;
  padding: 10px 0;
  font-size: 1.6rem;
  z-index: 40;
  border-radius: 50%;
  display: none;
  opacity: 0.7;
  .text {
    font-size: 0.8rem;
    color: $grey;
  }
  &:hover {
    opacity: 1;
  }
}

.productSelection {
  margin: 0 0 40px;
  .inner {
    position: relative;
    border-radius: 10px;
    border: 1px solid #e3e3e3;
    overflow: hidden;
    .lefter {
      position: absolute;
      width: 30px;
      height: 100%;
      color: $primary-color;
      text-align: center;
      z-index: 5;
      background-color: rgba(255, 255, 255, 0.9);
      top: 0;
      left: 0;
      cursor: pointer;
      > span {
        padding: 78px 0;
        display: inline-block;
        font-size: 1.8rem;
      }
    }
    .righter {
      position: absolute;
      width: 30px;
      height: 100%;
      color: $primary-color;
      text-align: center;
      z-index: 5;
      background-color: rgba(255, 255, 255, 0.9);
      top: 0;
      right: 0;
      cursor: pointer;
      > span {
        padding: 78px 0;
        display: inline-block;
        font-size: 1.8rem;
      }
    }
  }
  .view-my-products {
    border-radius: 10px;
    background-color: #303030;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    padding: 20px 40px;
    > .view-content {
      > .views-row {
        width: 120px;
        height: 150px;
        display: inline-block;
        overflow: hidden;
        border: 1px solid #e3e3e3;
        border-radius: 10px;
        margin: 0 3px;
        background-color: white;
        cursor: move;
        transition: all 0.3s;
        &:hover {
          tranform: scale(1.05);
        }
        &.views-row-last {
          margin-right: 60px;
        }
        .views-field-field-product-bild {
          text-align: center;
          border-bottom: 1px solid #e3e3e3;
          .product-data {
            width: auto !important;
            height: auto !important;
            text-align: center;
          }
          img {
            height: 85px;
            width: auto;
            max-width: none;
            display: inline-block;
          }
        }
        .views-field-title {
          padding: 5px;
          font-size: 0.9rem;
          font-weight: bold;
        }
        .views-field-field-sorte {
          padding: 0 5px 4px;
          font-size: 0.8rem;
        }
        .views-field-field-reihenabstand {
          padding: 0 5px;
          font-size: 0.8rem;
        }
        .views-field-field-darstellung {
          display: none;
        }
      }
    }
  }
}

.node-aktuelles {
  &.view-mode-full {
    .button.back {
      border: 3px solid $secondary-color;
      height: 60px;
      line-height: 60px;
      text-transform: uppercase;
      font-size: 1.2rem;
      background: white;
      padding: 0 20px 0 60px;
      color: $black;
      transition: all 0.3s;
      &:before {
        content: "\e906";
        font-family: 'erntemich';
        font-size: 1.8rem;
        display: inline-block;
        width: 54px;
        height: 54px;
        background: white;
        color: $black;
        position: absolute;
        left: 0;
        top: 0;
        transition: all 0.3s;
      }
      &:hover {
        background-color: $secondary-color;
        color: white;
        &:before {
          background-color: $secondary-color;
          color: white;
        }
      }
    }
    .metaInfo {
      .type {
        .field-name-field-aktuell-artikeltyp {
          display: inline-block;
        }
        ul {
          list-style: none;
          margin: 0;
          li {
            font-weight: bold;
            color: $primary-color;
          }
        }
      }
      .date {
        .field-name-field-aktuell-datum {
          display: inline-block;
        }
      }
    }
    .node-title {
      margin: 20px 0 35px;
    }
    .field-name-field-aktuell-bild {
      margin: 10px 0 40px;
    }
    .field-name-field-aktuell-text {
      font-size: 1.2rem;
      line-height: 1.8;
      p {
        font-size: 1.2rem;
        line-height: 1.8;
      }
    }
  }
  &.node-teaser {
    .medium-4 {
      padding: 0 0 20px;

    }
    h2 {
      a {
        color: $black;
      }
    }
    .metaInfo {
      .type {
        .field-name-field-aktuell-artikeltyp {
          display: inline-block;
        }
        ul {
          list-style: none;
          margin: 0;
          li {
            font-weight: bold;
            color: $primary-color;
          }
        }
      }
      .date {
        .field-name-field-aktuell-datum {
          display: inline-block;
        }
      }
    }
    .more {
      a {
        color: $secondary-color;
      }
    }
  }
  &.view-mode-token {
    display: inline-block;
    height: 500px;
    width: 300px;
    background-color: white;
    position: relative;
    overflow: hidden;
    .textField {
      padding: 10px 12px 15px;
      .metaInfo {
        .type {
          float: left;
          .field-name-field-aktuell-artikeltyp {
            display: inline-block;
          }
          ul {
            list-style: none;
            margin: 0;
            li {
              font-weight: bold;
              color: $primary-color;
            }
          }
        }
        .date {
          float: right;
          .field-name-field-aktuell-datum {
            display: inline-block;
          }
        }
      }
      h3.node-title {
        font-size: 1.2rem;
        margin: 15px 0 10px;
        a {
          color: $black;
          line-height: 1.4;
          font-weight: bold;
        }
      }
    }
    .more {
      display: inline-block;
      border-top: 1px solid #e3e3e3;
      position: absolute;
      bottom: 15px;
      width: 150px;
      left: 50%;
      margin-left: -75px;
      text-align: center;
      padding: 6px 0 0;
      a {
        color: $secondary-color;
        text-align: center;
      }
    }
  }
}

.view-pflanzenshop > .view-content {
  width: 100%;
  min-height: .01%;
  overflow-x: auto;
  .views-field-field-product-bild {
    width: 180px;
    min-width: 180px;
  }
}

.node-beet-pflanzen {
  .infos {
    .erntemich-icon-info {
      width: 60px;
      vertical-align: top;
      font-size: 2.8rem;
      display: inline-block;
    }
    .rightInfo {
      width: calc(100% - 65px);
      display: inline-block;
      vertical-align: top;
      padding: 8px 0 0;
      p {
        margin: 0 0 8px;
      }
    }
  }
  .help {
    margin: 0 0 30px;
    .erntemich-icon-fragezeichen {
      width: 60px;
      vertical-align: middle;
      font-size: 2.8rem;
      display: inline-block;
    }
    .rightInfo {
      width: calc(100% - 65px);
      display: inline-block;
      vertical-align: middle;
      strong {
        margin: 0 15px 0 0;
        display: inline-block;
        vertical-align: middle;
      }
      p {
        margin: 0 0 8px;
      }
    }
  }
  .prices {
    margin: 50px 0 0;
    .erntemich-icon-info {
      width: 60px;
      vertical-align: top;
      font-size: 2.8rem;
      display: inline-block;
    }
    .rightInfo {
      width: calc(100% - 65px);
      display: inline-block;
      vertical-align: top;
      strong {
        margin: 0 15px 0 0;
        display: inline-block;
        vertical-align: top;
      }
      p {
        margin: 0 0 8px;
      }
    }
  }
}

table {
  width: 100%;
}

.cardItem {
  position: relative;
  .number {
    background: red;
    color: white;
    font-size: 0.7rem;
    position: absolute;
    top: -9px;
    right: -15px;
    border-radius: 50%;
    width: 18px;
    height: 18px;
    text-align: center;
    padding: 4px 0;
    font-weight: bold;
    line-height: 1;
    div {
      display: inline;
    }
  }
}

.view-aktuelles-beet {
  .views-field.views-field-field-product-bild img {
    display: block;
    height: 100%;
    width: 100%;
  }
}

.views-field-field-product-bild {
  &.color_worst .field-content {
    background: red;
    img {
      opacity: 0.5;
    }
  }
  &.color_bad .field-content {
    background: rgb(255, 120, 120);
    img {
      opacity: 0.5;
    }
  }
  &.color_good .field-content {
    background: rgb(153, 219, 156);
    img {
      opacity: 0.5;
    }
  }
  &.color_best .field-content {
    background: green;
    img {
      opacity: 0.5;
    }
  }
  &.problem .field-content {
    background: gray;
    img {
      opacity: 0.4;
    }
  }
}

.beet {
  height: 382px;
  width: 952px;
  background: url('../images/erde.jpg') 0 0 repeat;
  border: 1px solid #233804;
  position: relative;
  margin: 50px 0 0 10px;
  .views-field-field-product-bild {
    position: absolute !important;
    display: block !important;
    cursor: move;
    img {
      display: block;
      height: 100%;
      width: 100%;
    }
  }
  .width {
    background: url(../images/width.png) 0 0 no-repeat;
    position: absolute;
    left: -1px;
    width: calc(100% + 2px);
    height: 30px;
    top: -35px;
    background-size: cover;
    display: block;
  }
  .height {
    background: url(../images/height.png) 0 0 repeat-y;
    position: absolute;
    left: -35px;
    width: 30px;
    height: 100%;
    top: 0;
    display: block;
    background-size: auto 380px;
  }
}

.interatkiveArea {
  margin: 20px 0;
  padding: 25px;
  .buttons {
    width: calc(30% - 20px);
    float: left;
    margin: 0 20px 0 0;
    .button {
      width: 250px;
      &.removeBeet {
        background-color: $secondary-color;
      }
    }
  }
  .trash {
    text-align: center;
    width: calc(30% - 20px);
    float: left;
    margin: 10px 20px 0 0;
    img {
      margin: 0 0 5px;
    }
    small {
      font-size: 0.8rem;
      margin: 5px 0 0;
      display: inline-block;
    }
  }
  .kasse {
    width: 40%;
    float: left;
    border: 3px solid #fd8524;
    text-align: center;
    padding: 10px 0 20px;
    margin: 8px 0 0;
    .numberss {
      font-size: 1.4rem;
      line-height: 1.5;
      margin: 0 0 10px;
      .price {
        div {
          display: inline;
        }
      }
      .size {
        div {
          display: inline;
        }
      }
    }
    .button {
      color: white;
      background-color: #fd8524;
      text-transform: uppercase;
      font-size: 1.5rem;
      font-family: museo_slab500;
      margin: 0;
    }
  }
}

.view-id-aktuelles {
  &.view-display-id-page {
    .views-row {
      border-bottom: 1px solid #e3e3e3;
      padding: 0 0 30px;
      margin: 0 0 25px;
      &.views-row-last {
        border: none;
      }
    }
  }
  &.view-display-id-block_1 {
    padding: 40px 0 30px;
    .views-row {
      display: inline-block;
      vertical-align: top;
    }
    .aktuellRahmen {
      position: relative;
      .navi {
        background: rgba(0, 0, 0, 0.4);
        height: 100%;
        width: 60px;
        position: absolute;
        top: 0;
        z-index: 10;
        cursor: pointer;
        > span {
          color: white;
          padding: 220px 10px;
          font-size: 2.5rem;
          display: inline-block;
        }
        &:hover {
          background: rgba(0, 0, 0, 0.6);
        }
        &.lefter {
          left: 0;
        }
        &.righter {
          right: 0;
        }
      }
    }
    .owl-carousel .owl-stage-outer {
      overflow: visible;
    }
  }
}

#shop {
  background-color: white;
  overflow: hidden;
  padding: 60px 0 70px;
  min-height: 800px;
  position: relative;
  h2.subtitle {
    font-size: rem-calc(40);
    margin: 0 0 50px;
    color: $black;
    text-align: center;
    z-index: 5;
    position: relative;
  }
  img.left_shop {
    position: absolute;
    left: -120px;
    bottom: 25px;
  }
  img.right_shop {
    position: absolute;
    right: -100px;
    top: 100px;
  }
  .produktshapes {
    position: relative;
    .sliderProducts {
      text-align: center;
      .owl-controls {
        .owl-nav {
          color: $primary-color;
          position: absolute;
          top: 70px;
          width: 100%;
          font-size: 2.5rem;
          .owl-prev {
            position: absolute;
            left: 0;
          }
          .owl-next {
            position: absolute;
            right: 0;
          }
        }
        .owl-dots {
          text-align: center;
          margin: 20px 0;
          .owl-dot {
            width: 15px;
            height: 15px;
            border-radius: 50%;
            margin: 0 5px;
            display: inline-block;
            background: lighten($primary-color, 23);
            &.active {
              background: $primary-color;
            }
          }
        }
      }
    }
    .p-box {
      position: relative;
      text-align: center;
      display: block;
      color: black;
      margin: 0 auto;
      .bg {
        transition: all 0.3s;
        position: absolute;
        width: 100%;
        height: 100%;
        background: url('/sites/all/themes/em2/images/prinzip/biokiste.png') no-repeat center center;
        background-size: contain;
      }
      &:hover {
        .text {
          span {
            background: $secondary-color;
          }
        }
      }
      .text {
        font-family: museo_slab500;
        font-size: 21px;
        line-height: 1.3;
        position: absolute;
        .texttable {
          display: table;
          width: 100%;
          height: 100%;
          .innertext {
            display: table-cell;
            vertical-align: middle;
          }
        }
        span {
          background: $primary-color;
          color: white;
          display: inline-block;
          padding: 3px 10px;
        }
      }
      &.center {
        width: 280px;
        height: 280px;
        z-index: 11;
        margin-bottom: 40px;
        .arm {
          top: calc(100% - 50px);
          left: 121px;
          position: absolute;
          width: 32px;
          height: 118px;
          background: url('/sites/all/themes/em2/images/prinzip/mobile_arm.png') no-repeat center center;
          background-size: contain;
        }
        .bg {
          background: url('/sites/all/themes/em2/images/prinzip/main.png') no-repeat center center;
          background-size: contain;
        }
        .text {
          color: white;
          top: 40px;
          left: 40px;
          width: 200px;
          height: 200px;
          span {
            background: white;
            color: black;
          }
        }
      }
      &.events {
        width: 140px;
        height: 140px;
        transform: translate(20px, -2px);
        .bg {
          background: url('/sites/all/themes/em2/images/prinzip/events.png') no-repeat center center;
          background-size: contain;
          transform-origin: 15px 110px;
        }
        .text {
          top: 20px;
          left: 0;
          width: 103px;
          height: 103px;
        }
      }
      &.biokiste {
        width: 220px;
        height: 220px;
        transform: translate(10px, 18px);
        .bg {
          background: url('/sites/all/themes/em2/images/prinzip/biokiste.png') no-repeat center center;
          background-size: contain;
          transform-origin: 20px 135px;
        }
        .text {
          top: 0px;
          left: 7px;
          width: 175px;
          height: 175px;
        }
      }
      &.markt {
        width: 200px;
        height: 200px;
        transform: translate(10px, 18px);
        .bg {
          background: url('/sites/all/themes/em2/images/prinzip/markt.png') no-repeat center center;
          background-size: contain;
          transform-origin: 55px 30px;
        }
        .text {
          top: -6px;
          left: 24px;
          width: 175px;
          height: 175px;
        }
      }
      &.restaurants {
        width: 160px;
        height: 160px;
        transform: translate(10px, 18px);
        .bg {
          background: url('/sites/all/themes/em2/images/prinzip/restaurants.png') no-repeat center center;
          background-size: contain;
          transform-origin: 110px 15px;
        }
        .text {
          top: 0;
          left: 12px;
          width: 135px;
          height: 135px;

        }
      }
      &.pflanzenshop {
        width: 240px;
        height: 240px;
        .bg {
          background: url('/sites/all/themes/em2/images/prinzip/garten.png') no-repeat center center;
          background-size: contain;
          transform-origin: calc(100% - 7px) calc(100% - 100px);
        }
        .text {
          top: 18px;
          left: 45px;
          width: 196px;
          height: 196px;
        }
      }
      &.gaerten {
        width: 220px;
        height: 220px;
        .bg {
          background: url('/sites/all/themes/em2/images/prinzip/gaerten.png') no-repeat center center;
          background-size: contain;
          transform-origin: calc(100% - 55px) calc(100% - 40px);
        }
        .text {
          top: 18px;
          left: 57px;
          width: 160px;
          height: 160px;
        }
      }
    }
  }
}

.about {
  background: url('../images/about.jpg') repeat center center;
  background-size: cover;
  padding: 60px 0 40px;
  text-align: center;
  .erntemich {
    width: 245px;
    height: 245px;
    text-align: center;
    margin: 0 0 25px;
    background-color: white;
    padding: 50px 0;
    border-radius: 50%;
    display: inline-block;
  }
  .text {
    color: $black;
    font-size: 1.5rem;
    max-width: 600px;
    display: block;
    margin: 10px auto;
    line-height: 1.6;
  }
}

.node-fotos-medien {
  background-color: #303030;
  padding: 40px 0 50px;
  h3 {
    color: white;
  }
  .galerie {
    .item {
      height: 460px;
      position: relative;
      transform: translateZ(0);
    }
    .owl-stage {
      transform: translateZ(0);
    }
    .owl-controls {
      .owl-prev {
        color: white;
        font-size: 3.2rem;
        position: absolute;
        left: 20px;
        top: 45%;
        &:hover {
          color: $primary-color;
        }
      }
      .owl-next {
        color: white;
        font-size: 3.2rem;
        position: absolute;
        right: 20px;
        top: 45%;
        &:hover {
          color: $primary-color;
        }
      }
      .owl-dots {
        text-align: center;
        padding: 25px 0 0;
        .owl-dot {
          width: 20px;
          height: 20px;
          border: 2px solid white;
          border-radius: 50%;
          background-color: transparent;
          margin: 0 5px;
          display: inline-block;
          &.active {
            background-color: white;
          }
        }
      }
    }
  }
}

.owl-carousel .owl-video-tn {
  background-size: cover !important;
}

@media (max-width: 1000px) {
  #shop {
    .left_shop, .right_shop {
      width: 200px;
      height: auto;
    }
  }
}

@media (max-width: 480px) {
  #shop {
    .upperProducts {
      .column {
        padding: 0;
        .proImg, .textblock {
          float: none;
          width: auto;
          margin: 0 0 10px;
          .text {
            margin: 0;
          }
        }
        .price {
          white-space: nowrap;
          .button.grey {
            padding-left: 26px;
            font-size: 1rem;
            &:after {
              display: none;
            }
          }
        }
      }
    }
    .productsUnder {
      .column {
        padding: 0;
        .proImg {
          float: none;
          width: auto;
          margin: 0 0 10px;
        }
        .textblock {
          float: none;
          width: auto;
          height: auto;
          margin: 0 0 40px;
        }
        .product {
          margin: 0 0 10px;
          .textblock h5 {
            height: auto;
            min-height: 0;
          }
        }
      }
    }
  }
}

@media #{$medium-up} {
  #shop {
    background-color: white;
    overflow: hidden;
    padding: 60px 0 70px;
    min-height: 800px;
    position: relative;
    h2.subtitle {
      font-size: rem-calc(40);
      margin: 0 0 50px;
      color: $black;
      text-align: center;
      z-index: 5;
      position: relative;
    }
    img.left_shop {
      position: absolute;
      left: -120px;
      bottom: 25px;
    }
    img.right_shop {
      position: absolute;
      right: -100px;
      top: 100px;
    }
    .produktshapes {
      min-height: 500px;
      position: relative;
      .p-box {
        position: absolute;
        height: 200px;
        width: 200px;
        text-align: center;
        display: block;
        top: 50%;
        left: 50%;
        color: black;
        .arm {
          position: absolute;
          z-index: 10;
        }
        .bg {
          transition: all 0.3s;
          position: absolute;
          width: 100%;
          height: 100%;
          background: url('/sites/all/themes/em2/images/prinzip/biokiste.png') no-repeat center center;
          background-size: contain;
        }
        &:hover {
          .bg {
            transform: scale(1.05);
          }
          .text {
            span {
              background: $secondary-color;
            }
          }
        }
        &.no-link {
          .bg {
            transform: none;
          }
        }
        .text {
          font-family: museo_slab500;
          font-size: 21px;
          line-height: 1.3;
          position: absolute;
          .texttable {
            display: table;
            width: 100%;
            height: 100%;
            .innertext {
              display: table-cell;
              vertical-align: middle;
            }
          }
          span {
            background: $primary-color;
            color: white;
            display: inline-block;
            padding: 3px 10px;
          }
        }
        &.center {
          width: 280px;
          height: 280px;
          transform: translate(-50%, -50%);
          z-index: 11;
          .bg {
            background: url('/sites/all/themes/em2/images/prinzip/main.png') no-repeat center center;
            background-size: contain;
          }
          .text {
            color: white;
            top: 40px;
            left: 40px;
            width: 200px;
            height: 200px;
            span {
              background: white;
              color: black;
            }
          }
        }
        &.events {
          width: 140px;
          height: 140px;
          transform: translate(40px, -262px);
          .arm {
            left: -21px;
            top: 105px;
            width: 48px;
            height: 60px;
            background: url('/sites/all/themes/em2/images/prinzip/arm_events.png') no-repeat center center;
            background-size: contain;
          }
          .bg {
            background: url('/sites/all/themes/em2/images/prinzip/events.png') no-repeat center center;
            background-size: contain;
            transform-origin: 15px 110px;
          }
          .text {
            top: 20px;
            left: 0;
            width: 103px;
            height: 103px;
          }
        }
        &.biokiste {
          width: 220px;
          height: 220px;
          transform: translate(180px, -150px);
          .arm {
            left: -95px;
            top: 120px;
            width: 120px;
            height: 17px;
            background: url('/sites/all/themes/em2/images/prinzip/arm_biokiste.png') no-repeat center center;
            background-size: contain;
          }
          .bg {
            background: url('/sites/all/themes/em2/images/prinzip/biokiste.png') no-repeat center center;
            background-size: contain;
            transform-origin: 20px 135px;
          }
          .text {
            top: 0px;
            left: 7px;
            width: 175px;
            height: 175px;
          }
        }
        &.markt {
          width: 200px;
          height: 200px;
          transform: translate(90px, 120px);
          .arm {
            left: -11px;
            top: -50px;
            width: 65px;
            height: 90px;
            background: url('/sites/all/themes/em2/images/prinzip/arm_markt.png') no-repeat center center;
            background-size: contain;
          }
          .bg {
            background: url('/sites/all/themes/em2/images/prinzip/markt.png') no-repeat center center;
            background-size: contain;
            transform-origin: 55px 30px;
          }
          .text {
            top: -6px;
            left: 24px;
            width: 175px;
            height: 175px;
          }
        }
        &.restaurants {
          width: 160px;
          height: 160px;
          transform: translate(-160px, 140px);
          .arm {
            right: 34px;
            top: -50px;
            width: 30px;
            height: 61px;
            background: url('/sites/all/themes/em2/images/prinzip/arm_restaurants.png') no-repeat center center;
            background-size: contain;
          }
          .bg {
            background: url('/sites/all/themes/em2/images/prinzip/restaurants.png') no-repeat center center;
            background-size: contain;
            transform-origin: 110px 15px;
          }
          .text {
            top: 0;
            left: 12px;
            width: 135px;
            height: 135px;

          }
        }
        &.pflanzenshop {
          width: 240px;
          height: 240px;
          transform: translate(-405px, -65px);
          .arm {
            right: -70px;
            bottom: 100px;
            width: 78px;
            height: 62px;
            background: url('/sites/all/themes/em2/images/prinzip/arm_garten.png') no-repeat center center;
            background-size: contain;
          }
          .bg {
            background: url('/sites/all/themes/em2/images/prinzip/garten.png') no-repeat center center;
            background-size: contain;
            transform-origin: calc(100% - 7px) calc(100% - 100px);
          }
          .text {
            top: 18px;
            left: 45px;
            width: 196px;
            height: 196px;
          }
        }
        &.gaerten {
          width: 220px;
          height: 220px;
          transform: translate(-315px, -270px);
          .arm {
            right: -30px;
            bottom: 20px;
            width: 85px;
            height: 35px;
            background: url('/sites/all/themes/em2/images/prinzip/arm_gaerten.png') no-repeat center center;
            background-size: contain;
          }
          .bg {
            background: url('/sites/all/themes/em2/images/prinzip/gaerten.png') no-repeat center center;
            background-size: contain;
            transform-origin: calc(100% - 55px) calc(100% - 40px);
          }
          .text {
            top: 18px;
            left: 57px;
            width: 160px;
            height: 160px;
          }
        }

      }
    }
  }
}

@media #{$small-only} {
  .view-id-pflanzeninfo {
    > .attachment {
      width: 100%;
      display: block;
      height: 200px;
      margin: 0 0 20px;
    }
  }
  .view-id-pflanzeninfo.view-display-id-block_1 > .view-content, .view-id-pflanzeninfo.view-display-id-page_1 > .view-content {
    display: block;
    width: 100%;
  }
  header.l-header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 155;
    .meta {
      display: none;
    }
    &.fixed-header {
      .meta {
        margin: 0;
      }
      .main-header {
        position: relative;
      }
    }
  }
  #shop .upperProducts .product .price .amount {
    font-size: 0.9rem;
    line-height: 1.25;
    width: 80px;
    height: 80px;
  }
  .para_knowledge .field-name-field-about-wissenwertes .entity-field-collection-item {
    width: 100% !important;
    margin: 0 0 25px !important;
    .content {
      margin: 0 !important;
      &:before {
        display: none !important;
      }
    }
  }

  main.l-main {
    margin: 90px auto 0;
    &.row {
      margin: 120px auto 35px;
    }
    .main {
      h1#page-title {
        font-size: rem-calc(28);
      }
      h2 {
        font-size: rem-calc(22);
      }
      h3 {
        font-size: rem-calc(20);
      }
      h4 {
        font-size: rem-calc(18);
      }
    }
  }
  .l-footer {
    .knowFrom {
      .view-id-bekannt_aus {
        .erntemich-icon-arrow-left {
          font-size: 2.2rem;
        }
        .erntemich-icon-arrow-right {
          font-size: 2.2rem;
        }
      }
      .node-bekannt-aus {
        .gradient {
          width: 18px;
        }
        .field-name-field-bekanntaus-logos {
          padding: 0;
          img {
            margin: 0 15px;
          }
        }
      }
    }
    .downSide {
      text-align: center;
      .left, .right {
        float: none !important;
      }
    }
  }
  .node-aktuelles {
    &.node-teaser {
      .medium-8 {
        padding: 0 0 20px;
      }
    }
  }

  #shop {
    h2.subtitle {
      font-size: 1.8rem;
    }
  }

  #back-top {
    bottom: 30px;
    right: 30px;
  }
}