@font-face {
  font-family: 'museo_slab500';
  src: url('../fonts/museoslab_500_macroman/Museo_Slab_500_2-webfont.eot');
  src: url('../fonts/museoslab_500_macroman/Museo_Slab_500_2-webfont.eot?#iefix') format('embedded-opentype'),
  url('../fonts/museoslab_500_macroman/Museo_Slab_500_2-webfont.woff2') format('woff2'),
  url('../fonts/museoslab_500_macroman/Museo_Slab_500_2-webfont.woff') format('woff'),
  url('../fonts/museoslab_500_macroman/Museo_Slab_500_2-webfont.ttf') format('truetype'),
  url('../fonts/museoslab_500_macroman/Museo_Slab_500_2-webfont.svg#museo_slab500') format('svg');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'ubuntu';
  src: url('../fonts/ubuntu/ubuntu-b-webfont.eot');
  src: url('../fonts/ubuntu/ubuntu-b-webfont.eot?#iefix') format('embedded-opentype'),
  url('../fonts/ubuntu/ubuntu-b-webfont.woff2') format('woff2'),
  url('../fonts/ubuntu/ubuntu-b-webfont.woff') format('woff'),
  url('../fonts/ubuntu/ubuntu-b-webfont.ttf') format('truetype'),
  url('../fonts/ubuntu/ubuntu-b-webfont.svg#ubuntubold') format('svg');
  font-weight: 600;
  font-style: normal;

}

@font-face {
  font-family: 'ubuntu';
  src: url('../fonts/ubuntu/ubuntu-bi-webfont.eot');
  src: url('../fonts/ubuntu/ubuntu-bi-webfont.eot?#iefix') format('embedded-opentype'),
  url('../fonts/ubuntu/ubuntu-bi-webfont.woff2') format('woff2'),
  url('../fonts/ubuntu/ubuntu-bi-webfont.woff') format('woff'),
  url('../fonts/ubuntu/ubuntu-bi-webfont.ttf') format('truetype'),
  url('../fonts/ubuntu/ubuntu-bi-webfont.svg#ubuntubold_italic') format('svg');
  font-weight: 600;
  font-style: italic;

}

@font-face {
  font-family: 'ubuntu';
  src: url('../fonts/ubuntu/ubuntu-l-webfont.eot');
  src: url('../fonts/ubuntu/ubuntu-l-webfont.eot?#iefix') format('embedded-opentype'),
  url('../fonts/ubuntu/ubuntu-l-webfont.woff2') format('woff2'),
  url('../fonts/ubuntu/ubuntu-l-webfont.woff') format('woff'),
  url('../fonts/ubuntu/ubuntu-l-webfont.ttf') format('truetype'),
  url('../fonts/ubuntu/ubuntu-l-webfont.svg#ubuntulight') format('svg');
  font-weight: 300;
  font-style: normal;

}

@font-face {
  font-family: 'ubuntu';
  src: url('../fonts/ubuntu/ubuntu-li-webfont.eot');
  src: url('../fonts/ubuntu/ubuntu-li-webfont.eot?#iefix') format('embedded-opentype'),
  url('../fonts/ubuntu/ubuntu-li-webfont.woff2') format('woff2'),
  url('../fonts/ubuntu/ubuntu-li-webfont.woff') format('woff'),
  url('../fonts/ubuntu/ubuntu-li-webfont.ttf') format('truetype'),
  url('../fonts/ubuntu/ubuntu-li-webfont.svg#ubuntulight_italic') format('svg');
  font-weight: 300;
  font-style: italic;

}

@font-face {
  font-family: 'ubuntu';
  src: url('../fonts/ubuntu/ubuntu-m-webfont.eot');
  src: url('../fonts/ubuntu/ubuntu-m-webfont.eot?#iefix') format('embedded-opentype'),
  url('../fonts/ubuntu/ubuntu-m-webfont.woff2') format('woff2'),
  url('../fonts/ubuntu/ubuntu-m-webfont.woff') format('woff'),
  url('../fonts/ubuntu/ubuntu-m-webfont.ttf') format('truetype'),
  url('../fonts/ubuntu/ubuntu-m-webfont.svg#ubuntumedium') format('svg');
  font-weight: 500;
  font-style: normal;

}

@font-face {
  font-family: 'ubuntu';
  src: url('../fonts/ubuntu/ubuntu-mi-webfont.eot');
  src: url('../fonts/ubuntu/ubuntu-mi-webfont.eot?#iefix') format('embedded-opentype'),
  url('../fonts/ubuntu/ubuntu-mi-webfont.woff2') format('woff2'),
  url('../fonts/ubuntu/ubuntu-mi-webfont.woff') format('woff'),
  url('../fonts/ubuntu/ubuntu-mi-webfont.ttf') format('truetype'),
  url('../fonts/ubuntu/ubuntu-mi-webfont.svg#ubuntumedium_italic') format('svg');
  font-weight: 500;
  font-style: italic;

}

@font-face {
  font-family: 'ubuntu';
  src: url('../fonts/ubuntu/ubuntu-r-webfont.eot');
  src: url('../fonts/ubuntu/ubuntu-r-webfont.eot?#iefix') format('embedded-opentype'),
  url('../fonts/ubuntu/ubuntu-r-webfont.woff2') format('woff2'),
  url('../fonts/ubuntu/ubuntu-r-webfont.woff') format('woff'),
  url('../fonts/ubuntu/ubuntu-r-webfont.ttf') format('truetype'),
  url('../fonts/ubuntu/ubuntu-r-webfont.svg#ubunturegular') format('svg');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'ubuntu';
  src: url('../fonts/ubuntu/ubuntu-ri-webfont.eot');
  src: url('../fonts/ubuntu/ubuntu-ri-webfont.eot?#iefix') format('embedded-opentype'),
  url('../fonts/ubuntu/ubuntu-ri-webfont.woff2') format('woff2'),
  url('../fonts/ubuntu/ubuntu-ri-webfont.woff') format('woff'),
  url('../fonts/ubuntu/ubuntu-ri-webfont.ttf') format('truetype'),
  url('../fonts/ubuntu/ubuntu-ri-webfont.svg#ubuntuitalic') format('svg');
  font-weight: normal;
  font-style: italic;

}


@font-face {
  font-family: 'erntemich';
  src:url('../fonts/erntemich/fonts/erntemich.eot?c7wu81');
  src:url('../fonts/erntemich/fonts/erntemich.eot?c7wu81#iefix') format('embedded-opentype'),
  url('../fonts/erntemich/fonts/erntemich.ttf?c7wu81') format('truetype'),
  url('../fonts/erntemich/fonts/erntemich.woff?c7wu81') format('woff'),
  url('../fonts/erntemich/fonts/erntemich.svg?c7wu81#erntemich') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="erntemich-icon-"], [class*=" erntemich-icon-"] {
  font-family: 'erntemich';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.erntemich-icon-fragezeichen:before {
  content: "\e911";
}
.erntemich-icon-info:before {
  content: "\e912";
}
.erntemich-icon-menu:before {
  content: "\e910";
}
.erntemich-icon-left:before {
  content: "\e900";
}
.erntemich-icon-right:before {
  content: "\e901";
}
.erntemich-icon-arrow-right2:before {
  content: "\e905";
}
.erntemich-icon-arrow-left2:before {
  content: "\e906";
}
.erntemich-icon-arrow-down:before {
  content: "\e907";
}
.erntemich-icon-arrow-up:before {
  content: "\e908";
}
.erntemich-icon-arrow-right:before {
  content: "\e902";
}
.erntemich-icon-arrow-left:before {
  content: "\e903";
}
.erntemich-icon-aktuelles:before {
  content: "\e904";
}
.erntemich-icon-facebook:before {
  content: "\e909";
}
.erntemich-icon-facebook2:before {
  content: "\e90a";
}
.erntemich-icon-paper-plane:before {
  content: "\e90c";
}
.erntemich-icon-email:before {
  content: "\e90d";
}
.erntemich-icon-mail:before {
  content: "\e90e";
}
.erntemich-icon-old-phone:before {
  content: "\e90f";
}
.erntemich-icon-basket:before {
  content: "\e90b";
}
